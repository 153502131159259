<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('turkmer_page_title') + ' | '"
                        :isFilter="false"
                        :other-button="{show:true,title:$t('transfer_applications'),emit:'transferApplications'}"
                        @transferApplications="transferApplications"
                >
                </Header>
            </template>
            <template v-slot:header-mobile>
                <header-mobile :title="$t('turkmer_page_title')"
                               :isFilter="false">
                </header-mobile>
            </template>
            <b-tabs content-class="pt-5 position-relative" class="line-tabs" v-model="tabIndex">
                <ValidationObserver ref="form">
                    <b-tab :title="$t('personal_info')" active ref="tab1">
                        <b-row class="border">
                            <b-col md="8" sm="6" xs="12" lg="12">
                                <b-row>
                                    <b-col md="4" sm="6" lg="4" class="d-flex flex-column p-3">
                                        <b-col>
                                            <ValidationProvider name="passport_number"
                                                                :rules="!isDisabledPassportNo ? '' : 'required'"
                                                                v-slot="{valid, errors}">
                                                <b-form-group :label="$t('passport_number')">
                                                    <b-form-input type="text" size="sm"
                                                                  v-model="formData.passport_number"
                                                                  :disabled="isDisabledNationalId"
                                                    ></b-form-input>
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="national_id"
                                                                :rules="!isDisabledNationalId ? '' : 'required'"
                                                                v-slot="{valid, errors}">
                                                <b-form-group :label="$t('tc_no')">
                                                    <b-form-input type="text" size="sm" v-model="formData.national_id"
                                                                  :disabled="isDisabledPassportNo"
                                                                  v-check-min-max-value></b-form-input>
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="name" rules="required" v-slot="{valid, errors}">
                                                <b-form-group :label="$t('name')">
                                                    <b-form-input
                                                        v-model="formData.name"
                                                    ></b-form-input>
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="surname" rules="required"
                                                                v-slot="{valid, errors}">
                                                <b-form-group :label="$t('surname')">
                                                    <b-form-input
                                                        v-model="formData.surname"
                                                    ></b-form-input>
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider
                                                name="city_id"
                                                rules="required"
                                                v-slot="{ valid, errors }"
                                            >
                                                <b-form-group :label="$t('city')">
                                                    <city-selectbox v-model="formData.city_id"/>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-col>
                                    <b-col md="4" sm="6" lg="4" class="d-flex flex-column p-3">
                                        <b-col>
                                            <ValidationProvider name="gender" rules="required"
                                                                v-slot="{valid, errors}">
                                                <b-form-group :label="$t('gender')">
                                                    <gender-selectbox v-model="formData.gender"
                                                                      :validate-error="errors[0]">
                                                    </gender-selectbox>
                                                </b-form-group>
                                            </ValidationProvider>

                                            <ValidationProvider name="birthdate" rules="required"
                                                                v-slot="{valid, errors}">
                                                <b-form-group :label="$t('date_of_birth')" class="position-relative">
                                                    <v-date-picker
                                                        v-model="formData.birthdate"
                                                        :locale="$i18n.locale"
                                                        is-expanded
                                                        :popover="{ 'visibility': 'click' }">

                                                        <template
                                                            v-slot="{ inputValue, inputEvents }">
                                                            <b-input-group>
                                                                <b-form-input
                                                                    :readonly="true"
                                                                    :value="inputValue"
                                                                    v-on="inputEvents"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                                <b-input-group-append>
                                                                    <b-button
                                                                        variant="outline-secondary"
                                                                        class="btn-40"
                                                                        disabled
                                                                    >
                                                                        <i class="ri-calendar-line"></i
                                                                        ></b-button>
                                                                </b-input-group-append>
                                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                                         v-html="errors[0]"></b-form-invalid-feedback>

                                                            </b-input-group>
                                                        </template>
                                                    </v-date-picker>
                                                </b-form-group>
                                            </ValidationProvider>


                                            <ValidationProvider name="nationality" rules="required"
                                                                v-slot="{valid, errors}">

                                                <b-form-group :label="$t('nationality')">
                                                    <country-selectbox valueType="code"
                                                                       v-model="formData.nationality_code"
                                                                       :placeholder="$t('nationality')"
                                                                       :state="errors[0] ? false:null"
                                                                       :validate-error="errors[0]"/>
                                                </b-form-group>
                                            </ValidationProvider>


                                            <ValidationProvider name="mobile_tel"
                                                                maxlength="11"
                                                                rules="required"
                                                                v-slot="{ valid, errors }">
                                                <b-form-group :label="$t('gsm_no')">
                                                    <b-form-input v-model="formData.mobile_tel"
                                                                  :placeholder="$t('gsm_no')"
                                                                  v-mask="'5##-###-####'"
                                                                  :state="errors[0] ? false : null"/>
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="district_id" rules="required" v-slot="{ errors }">
                                                <b-form-group :label="$t('district')">
                                                    <district-outline-selectbox v-model="formData.district_id"
                                                                                :city_id="formData.city_id"
                                                                                :validateError="errors[0]"/>
                                                </b-form-group>
                                            </ValidationProvider>

                                        </b-col>
                                    </b-col>
                                    <b-col md="4" sm="6" lg="4" class="d-flex flex-column p-3">
                                        <b-col>
                                            <ValidationProvider name="email" rules="required" v-slot="{valid, errors}">
                                                <b-form-group :label="$t('email')">
                                                    <b-form-input type="text"
                                                                  v-model="formData.email"
                                                                  :state="errors[0] ? false : (valid ? true : null)">
                                                    </b-form-input>
                                                    <b-form-invalid-feedback v-if="errors[0]"
                                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="emergency_person" rules="required"
                                                                v-slot="{valid, errors}">
                                                <b-form-group :label="$t('emergency_person')">
                                                    <b-form-input type="text"
                                                                  v-model="formData.emergency_person"
                                                                  :state="errors[0] ? false : (valid ? true : null)">
                                                    </b-form-input>
                                                    <b-form-invalid-feedback v-if="errors[0]"
                                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <ValidationProvider name="emergency_person_number"
                                                                maxlength="11"
                                                                rules="required"
                                                                v-slot="{ valid, errors }">
                                                <b-form-group :label="$t('emergency_person_phone')">
                                                    <b-form-input v-model="formData.emergency_person_number"
                                                                  :placeholder="$t('gsm_no')"
                                                                  v-mask="'5##-###-####'"
                                                                  :state="errors[0] ? false : null"/>
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                                </b-form-group>
                                            </ValidationProvider>

                                            <ValidationProvider
                                                name="address"
                                                rules="required"
                                                v-slot="{ valid, errors }"
                                            >
                                                <b-form-group :label="$t('address')">
                                                    <b-form-textarea
                                                        v-model="formData.address"
                                                    ></b-form-textarea>
                                                </b-form-group>
                                                <b-form-invalid-feedback
                                                    v-if="errors[0]"
                                                    v-html="errors[0]"
                                                    class="mb-2"
                                                ></b-form-invalid-feedback>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>

                    </b-tab>
                    <b-tab :title="$t('program_select')" ref="tab2">
                        <b-row class="border">
                            <b-col md="8" sm="6" xs="12" lg="12">
                                <b-row>
                                    <b-col md="6">
                                        <ValidationProvider name="program_type" rules="required"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('course_exam_type')">
                                                <multiselect
                                                    placeholder="Seçiniz"
                                                    v-model="formData.program_type"
                                                    :options="courseCodeOptions"
                                                    track-by="value"
                                                    label="text"
                                                    :searchable="false"
                                                    :class="errors[0] ? 'is-invalid' : (valid ? 'is-valid' : null)"
                                                ></multiselect>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="6">
                                        <ValidationProvider name="program_code" rules="required"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('program')">
                                                <multiselect placeholder="seçiniz"
                                                             v-model="formData.program_code"
                                                             :options="filteredProgramCodeOptions" :select-label="''"
                                                             label="text"
                                                             track-by="value"
                                                             :selected-label="''" :deselect-label="''"
                                                             :searchable="false"
                                                             :class="errors[0] ? 'is-invalid' : (valid ? 'is-valid' : null)"
                                                >
                                                </multiselect>
                                            </b-form-group>
                                            <div class="invalid-feedback d-block" v-if="errors[0]"
                                                 v-html="errors[0]"></div>

                                        </ValidationProvider>
                                    </b-col>
                                    <b-col md="6" sm="6" lg="6" class="d-flex flex-column p-3">
                                        <b-col>
                                            <ValidationProvider name="before_turkish_certificate_status" rules=""
                                                                v-slot="{errors}">
                                                <b-form-group :label="$t('language_text')" v-slot="{ ariaDescribedby }">
                                                    <b-form-radio-group
                                                        v-model="formData.before_turkish_certificate_status"
                                                        :options="options"
                                                        :aria-describedby="ariaDescribedby"
                                                    ></b-form-radio-group>
                                                    <div class="invalid-feedback d-block" v-if="errors[0]"
                                                         v-html="errors[0]"></div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col v-if="formData.before_turkish_certificate_status===1" class="mt-3">
                                            <ValidationProvider name="before_turkish_certificate"
                                                                :rules="formData.before_turkish_certificate_status===1?'required':''"
                                                                v-slot="{errors}">
                                                <b-form-group :label="$t('certificate_upload')">
                                                    <div class="d-flex custom-file-upload">
                                                        <b-form-file
                                                            v-model="formData.before_turkish_certificate"
                                                            :state="errors[0] ? false : null"
                                                            :placeholder="$t('select_file')"
                                                            :drop-placeholder="$t('drop_file')"
                                                            ref="fileInput"
                                                        ></b-form-file>
                                                        <b-button variant="outline-secondary"
                                                                  @click="$refs.fileInput.$el.childNodes[0].click();">
                                                            {{ $t('browse') }}
                                                        </b-button>
                                                    </div>
                                                    <div class="alert alert-warning mt-3">
                                                        {{ $t('only_pdf_files_can_be_uploaded') }}
                                                    </div>
                                                    <div class="invalid-feedback d-block" v-if="errors[0]"
                                                         v-html="errors[0]"></div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab :title="$t('documents')" ref="tab3">
                        <b-row class="border">
                            <b-col cols="12">
                                <h6>{{ $t('documents') }}</h6>
                            </b-col>
                            <b-col cols="12">
                                <div class="d-flex flex-wrap">
                                    <b-card :sub-title="index === 'image' ? $t('image') : $t('before_turkish_certificate')"
                                            class="mr-2" style="min-width: 18rem;"
                                            v-for="(doc, index) in formData.documents" :key="index">
                                        <b-card-text>{{ $t('status') }}: {{ $t(`status_${doc.status}`) }}</b-card-text>
                                        <template #footer>
                                            <div class="d-flex flex-row justify-content-around">
                                                <a class="card-link"
                                                   @click="showDoc(doc,index)">{{ $t('download') }}</a>
                                                <b-link v-if="doc.status !== 'accepted'"
                                                        class="card-link text-success"
                                                        @click="approveDoc(doc, index, 'accepted')">
                                                    {{ $t('approve') }}
                                                </b-link>
                                                <b-link v-if="doc.status !== 'denied'"
                                                        class="card-link text-danger ml-3"
                                                        @click="approveDoc(doc, index, 'denied')">
                                                    {{ $t('reject') }}
                                                </b-link>
                                            </div>
                                        </template>
                                    </b-card>
                                </div>
                            </b-col>
                            <b-col cols="12" class="mt-2">
                                <ValidationProvider name="status" rules="" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('approval_status')">
                                        <parameter-selectbox v-model="formData.status"
                                                             :code="'turkmer_application_status'"
                                                             :validate-error="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                                <ValidationProvider name="rejectMessage"
                                                    :rules="formData.status == 'denied' ? 'required':''"
                                                    v-slot="{errors}">
                                    <b-form-group :label="$t('reject_why')"
                                                  v-if="formData.status == 'denied'">
                                        <b-form-textarea
                                            id="textarea"
                                            v-model="formData.reason"
                                            :placeholder="$t('')"
                                            rows="3"
                                            max-rows="6"
                                        ></b-form-textarea>
                                        <div class="invalid-feedback d-block" v-if="errors[0]"
                                             v-html="errors[0]"></div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="6">
                                <b-button variant="primary" @click="sendForm">
                                    {{ $t('send_application').toUpper() }}
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-tab>

                </ValidationObserver>
            </b-tabs>
        </app-layout>
    </div>
</template>
<script>
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import CitySelectbox from "@/components/interactive-fields/CitySelectbox.vue";
import MultiText from "@/components/interactive-fields/MultiText.vue";
import TurkmerApplicationProgramSelectBox from "@/components/interactive-fields/TurkmerApplicationProgramSelectBox.vue";
import TurkmerApplicationService from "../../../services/TurkmerApplicationService";
import DistrictOutlineSelectbox from "@/components/interactive-fields/DistrictOutlineSelectbox.vue";
import moment from "moment";
import qs from "qs";
import programService from "@/services/ProgramService";

export default {
    components: {
        DistrictOutlineSelectbox,
        TurkmerApplicationProgramSelectBox,
        MultiText,
        ParameterSelectbox,
        CitySelectbox,
    },
    data() {
        return {
            programs: [],
            courseCodeOptions: [
                {text: this.$t('course_code_option'), value: 'Course'},
                {text: this.$t('exam'), value: 'Exam'},
            ],
            tabIndex: null,
            masks: {
                input: "DD-MM-YYYY",
            },
            formData: {
                national_id: null,
                approval_status: null,
                name: null,
                surname: null,
                image: null,
                gender: null,
                birthdate: null,
                nationality_code: null,
                mobile_tel: null,
                email: null,
                emergency_person: null,
                emergency_person_phone: null,
                address: null,
                program: null,
                code: null,
                selected: null,
                program_type: null,
                program_code: null,
                district_id: null,
                documents: null,
            },
            queryParams: {
                filter: {
                    faculty_code: '50'
                },
            },
            options: [
                {text: 'Yes', value: 1},
                {text: 'No', value: 0},
            ],
            isDisabledPassportNo: false,
            isDisabledNationalId: false,
        }
    },
    async created() {
        await this.getPrograms();
        this.getData(this.$route.params.id)
    },
    watch: {
        'formData.program_type'(newValue, oldValue) {
            if (oldValue) {
                this.programCodeOptions = [];
                this.formData.program_code = null;
            }
        },
        'formData.passport_number'(newValue) {
            this.isDisabledPassportNo = !!newValue;
            if (this.isDisabledPassportNo) {
                this.formData.national_id = null;
            }
        },
        'formData.national_id'(newValue) {
            this.isDisabledNationalId = !!newValue;
            if (this.isDisabledNationalId) {
                this.formData.passport_number = null;
            }
        }
    },
    methods: {
        redirectTab() {
            let tab1 = this.$refs.tab1.$el.querySelector('.is-invalid,.box-border-color')
            let tab2 = this.$refs.tab2.$el.querySelector('.is-invalid,.box-border-color')
            let tab3 = this.$refs.tab3.$el.querySelector('.is-invalid,.box-border-color')

            if (tab1) return this.tabIndex = 0;
            if (tab2) return this.tabIndex = 1;
            if (tab3) return this.tabIndex = 2;
        },
        getData(id) {
            TurkmerApplicationService.show(id)
                .then(response => {
                    this.formData = response.data.data
                    this.formData.program_type = this.courseCodeOptions.find(option => option.value === this.formData.program_type);
                    this.formData.program_code = this.filteredProgramCodeOptions.find(option => option.value == this.formData.program_code);
                    if (this.formData.national_id === 'null') this.formData.national_id = '';

                }).catch(err => {
                this.showErrors(err)
            })
        },
        async sendForm() {
            let isValid = await this.$refs.form.validate()
            this.redirectTab()
            if (isValid) {
                this.formData.birthdate = moment(this.formData.birthdate).format("YYYY-MM-DD");
                const formData = new FormData();
                formData.append('address', this.formData.address);
                formData.append('birthdate', this.formData.birthdate);
                formData.append('city_id', this.formData.city_id);
                formData.append('district_id', this.formData.district_id);
                formData.append('email', this.formData.email);
                formData.append('emergency_person', this.formData.emergency_person);
                formData.append('emergency_person_number', this.formData.emergency_person_number);
                formData.append('gender', this.formData.gender);
                formData.append('mobile_tel', this.formData.mobile_tel);
                formData.append('name', this.formData.name);
                formData.append('nationality_code', this.formData.nationality_code);
                formData.append('program_code', this.formData.program_code.value);
                formData.append('program_type', this.formData.program_type.value);
                formData.append('reason', this.formData.reason);
                formData.append('semester_id', this.formData.semester_id);
                formData.append('status', this.formData.status);
                formData.append('surname', this.formData.surname);
                formData.append('pin', this.formData.pin);

                if (this.formData.national_id) {
                    formData.append('national_id', this.formData.national_id)
                }

                if (this.formData.passport_number) {
                    formData.append('passport_number', this.formData.passport_number);
                }

                TurkmerApplicationService.update(this.$route.params.id, formData)
                    .then(response => {
                        if (response.data.success) {
                            this.$toast.success(this.$t('application_approval_process_successful'));
                            this.$router.push('/turkmer/application/list')
                        } else {
                            this.$toast.error(this.$t('error'));
                        }
                    }).catch(err => {
                    this.showErrors(err)
                })
            }
        },
        transferApplications() {
            alert('asdllasldas')
        },
        async getPrograms() {
            const config = {
                params: {
                    ...this.queryParams,
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };
            await programService.getProgramOptions(config)
                .then(response => {
                    this.programs = response.data.data
                    this.programs.forEach(itm => {
                        this.filteredProgramCodeOptions.push({
                            text: itm.code + '-' + itm.name,
                            value: itm.code
                        })
                    })
                }).catch(err => {
                    this.showError(err)
                })
        },
        async showDoc(doc, type) {
            const config = {
                params: {
                    type: type,
                    turkmer_application_id: this.$route.params.id,
                    file: doc,
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };
            config.responseType = 'arraybuffer';

            TurkmerApplicationService.downloadAdminDocuments(config)
                .then(response => {
                    this._downloadFile(response, doc.filename)
                }).catch(err => {
                this.showErrors(err)
            })
        },
        async approveDoc(file, type, status) {
            const response = await this.$swal({
                text: status == 'accepted' ? this.$t('are_you_sure_to_approve') : status == 'denied' ? this.$t('are_you_sure_to_reject') : '',
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            })
            if (response.isConfirmed !== true) {
                return
            }
            const data = {
                turkmer_application_id: this.$route.params.id,
                type,
                status
            }
            const res = await TurkmerApplicationService.approveDocument(data).catch((e) =>
                this.$toast.error(this.$t("api." + e.data.message))
            );

            if (res.data.success) {
                const updateDocument = {
                    ...this.formData.documents[type],
                    status
                };
                this.$set(this.formData.documents, type, updateDocument);
            }

            this.$toast.success(this.$t('api.' + res.data.message));
        },
        getDocType(type) {
            return type === 'before_turkish_certificate'
                ? this.$t('before_turkish_certificate')
                : this.$t('image');
        }
    },
    computed: {
        filteredProgramCodeOptions() {
            if (!this.formData.program_type) {
                return [];
            }
            if (this.formData.program_type.value === 'Exam') {
                return this.programs
                    .filter(itm => ['50020018'].includes(itm.code))
                    .map(itm => ({
                        text: itm.code + '-' + itm.name,
                        value: itm.code
                    }));
            } else if (this.formData.program_type.value === 'Course') {
                return this.programs
                    .filter(itm => !['50020018'].includes(itm.code))
                    .map(itm => ({
                        text: itm.code + '-' + itm.name,
                        value: itm.code
                    }));
            }
            return [];
        }
    },
}
</script>
